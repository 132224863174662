.top-logo {

    position: static;
    height: auto;
    z-index: 1;
    text-align: center;
    padding: 10px;

    img {
        vertical-align: bottom;
    }

    &__icon {
        margin-bottom: 10px;
    }

    @media (min-width: 700px) {
        position: absolute;
        top: 45px;
        margin-left: 136px;
        width: calc(100% - 136px);
        height: calc(#{$top-branding-height} - 45px);
        text-align: left;
        padding: 0;

        &__icon {
            position: absolute;
            bottom: 10px;
            max-height: 75%;
        }

    }

}
