/**
 * Flag object.
 * http://csswizardry.com/2013/05/the-flag-object/
 *
 * Very similar to the media object, only the flag object allows for vertical alignment.
 * stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 */
.flag {
    display: table;
    width: 100%;

    &__image,
    &__body {
        display: table-cell;
        vertical-align: middle;

        .flag--top & {
            vertical-align: top;
        }

        .flag--bottom & {
            vertical-align: bottom;
        }

    }

    &__image {
        padding-right: 10px;

        > img {
            display: block;
            max-width: none;
        }

        .flag--rev & {
            padding-right: 0;
            padding-left: 10px;
        }

    }

    &__body {
        width: 100%;
    }

}
