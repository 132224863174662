blockquote {
    background-color: $ghost;
    border-top: 6px solid $gainsboro;
    border-bottom: 1px solid $gainsboro;
    border-left: none;
    margin-left: auto;
    margin-right: auto;

    cite {
        padding-bottom: .5625rem;
    }

    @media screen and (min-width: 520px) {
        width: 70%;
    }
}
