.top-branding {
    position: relative;

    &__icon {
        display: none;
        background-color: $white;
        max-height: 100%;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    @media (min-width: 700px) {
        height: $top-branding-height;

        &__icon {
            display: block;
        }

    }
}
