.sub-nav-container {
    background-color: $oil;
    height: 2.8125rem;
}

.sub-nav {
    background-color: $oil;
}

.sub-nav dt a, .sub-nav dd a, .sub-nav li a {
    color: $white;
}
