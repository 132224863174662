.u {
    &-hide {
        display: none !important;
    }

    &-center {
        text-align: center !important;
    }

    &-inverse {
        background-color: $primary-color;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }

        a {
            color: $white;

            &:hover {
                darken: ($white, 20%);
            }
        }
    }

    &-aligned-bottom {
        position: relative;
        padding-bottom: 2.5rem !important;
    }

    &-align-bottom {
        position: absolute;
        bottom: 1rem;
        margin-bottom: 0;
    }

    &-panel-aligned-bottom {
        position: relative;
        padding-bottom: 4rem;
    }

    &-panel-align-bottom {
        position: absolute;
        bottom: 0;
    }
}
