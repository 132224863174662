.breadcrumbs {

    // Truncate overly long breadcrumbs
    a {
        max-width: 125px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (min-width: 40em) {
        a {
            max-width: 500px;
        }
    }

}
