@media (min-width: 700px) {

    .top-title {
        position: absolute;
        margin-left: 136px;
        top: 0;
        left: 0;
        right: 0;
    }

}
