.modal {

    @media screen and (max-width: 1120px) {

        &__body img {
            // Stop images stretching at small sizes
            float: none !important;
            width: 100% !important;
            height: auto !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

    }

}
