.article-body {

    &--center {

        img {
            margin: 0px 10px 10px 0px;
        }

    }

    @media screen and (max-width: 600px) {

        // Stop mahi-tahi images stretching at small sizes
        img {
            height: auto !important;
        }

    }

}
