.featured-gallery {

    &__image {
        position:relative;
        cursor: pointer;

        img {
            width: 100%
        }

        &:before {
            content:'';
            position:absolute;
            width:100%; height:100%;
            top:0; left:0;
            background-color :rgba(0,0,0,0.3);
            background-image: url(/assets/img/versioned/vector-nz-map.svg);
            background-size: auto 90%;
            background-position-x: calc(100% - 3%);
            background-position-y: calc(100% - 50%);
            background-repeat: no-repeat;
            opacity:0;
            transition: opacity 200ms ease-out;
        }

        &:hover:before,
        &.active:before {
            opacity: 1;
        }

        &:hover:after,
        &.active:after {
            content: '';
            position: absolute;
            top: 0; right: 0;
            width: 10px; height: 10px;
            border-radius: 50%;
            background-color: red;
        }

        &--whitiora-marae-te-tii:hover:after,
        &--whitiora-marae-te-tii.active:after {
            top: 6%; right: 22%;
        }
        &--huria-marae:hover:after,
        &--huria-marae.active:after {
            top: 22%; right: 10%;
        }
        &--nukuhau-marae:hover:after,
        &--nukuhau-marae.active:after {
            top: 27%; right: 11%;
        }
        &--ō-ā-kura-marae:hover:after,
        &--ō-ā-kura-marae.active:after {
            top: 34%; right: 19%;
        }
        &--p-ū-kaki-marae:hover:after,
        &--p-ū-kaki-marae.active:after {
            top: 13%; right: 19%;
        }
        &--waiwhetu-marae:hover:after,
        &--waiwhetu-marae.active:after {
            top: 46%; right: 13%;
        }
        &--omaka-marae:hover:after,
        &--omaka-marae.active:after {
            top: 48%; right: 18%;
        }
        &--tuahiwi-marae:hover:after,
        &--tuahiwi-marae.active:after {
            top: 60%; right: 22%;
        }
        &--te-rau-aroha-marae:hover:after,
        &--te-rau-aroha-marae.active:after {
            top: 86%; right: 38%;
        }

    }

    &__map-button {
        position: absolute;
        margin: 0;
        bottom: 4px;
        right: 4px;
        outline: 0;
    }

}
