.footer {
    background-color: $smoke !important;
    border-top: 1rem solid $primary-color;

    p {
        font-size: 0.85rem;
    }

    &__copyright {
        margin-top: 0;
        text-align: center;
    }

    &__contact {
        text-align: center;
        margin-bottom: 0;
    }

}
