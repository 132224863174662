/**
 * NEWS-ITEM
 */
.news-item {
    cursor: pointer;

    &__blurb {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-left: 10px;
        padding-top: 30px;
        padding-right: 10px;
        padding-bottom: 10px;
        background: linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) );
    }

    &__blurb h3 {
        font-size: 1.2rem;
        line-height: 1.2;
        text-shadow: 2px 2px 20px $black;
    }

    &__blurb a {
        color: $white;
    }

    &__blurb a:hover,
    &__blurb a:focus {
        color: $smoke;
    }
}

/**
 * NEWS-ITEM--BLOCK
 */
.news-item--block {
    margin-bottom: calc( 2 * 0.9375rem);

    .news-item__blurb {
        margin-left: 0.9375rem;
        margin-right: 0.9375rem;
    }
}
