.hero {
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    margin    : auto;
    max-width : rem-calc(850);

    &__blurb {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        background: linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) );
    }

    &__blurb h1 {
        line-height: 1.1;
        font-weight: 400;
        text-shadow: 2px 2px 20px $black;
    }

    &__blurb p {
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    &__blurb h1,
    &__blurb p,
    &__blurb a {
        color: $white;
    }

    &__blurb a:hover,
    &__blurb a:focus {
        color: $smoke;
    }

    @media (max-width: 820px) {
        &__blurb {
            position: static;
            background: $oil;
            padding-top: 10px;
            padding-right: 0.9375rem;
            overflow: hidden;
        }
    }

}
