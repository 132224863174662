/**
 * ACCORDION
 */
.accordion {
    margin-bottom: 1.5rem;
}

.accordion-navigation {
  >a:after {
    font-family: kep;
    content: "\e81b";
    color: $ghost;
    line-height: 1;
    float: right;
    margin-right: -2rem;
    font-size: 30px;
  }
  & > a:after:hover {
    color: scale-color($ghost, $lightness: -5%);
  }
  &.active > a:after {
    font-family: kep;
    content: "\e81a";
    color: $white;
  }
}

.accordion .accordion-navigation > a,
.accordion dd > a {
    padding-right: 3rem;
    border-bottom: 3px solid $gainsboro;
}

.accordion .accordion-navigation > a:hover,
.accordion dd > a:hover {
    color: scale-color($ghost, $lightness: -5%);
}

.accordion .accordion-navigation.active > a,
.accordion dd.active > a {
    color: $white;
}

.accordion__close {
    font-family: kep;
    color: red;
    line-height: 1;
    float: right;
    font-size: 30px;
    cursor: pointer;
}

.accordion-navigation.active > div[id^=accordionPanel] {
    padding-bottom: 3rem;
}
