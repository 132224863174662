.th {
    &.th--panel {
        padding: .625rem;
        margin-bottom: 1.25rem;

        &:hover,
        &:focus {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
        }

        img {
            margin-bottom: .625rem;
        }
    }

    &--icon {
        img {
            margin: 0;
        }
    }

    &.right {
        margin-left: 1em;
        margin-bottom: 1em;
    }

    @media screen and (min-width: 430px) {
        &.th--404 {
            max-width: 400px;
        }
    }
}
