.reveal-modal {

    >:first-child {
        margin-top: 25px;
    }

    .close-reveal-modal {
        background-color: $white;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 0.6;
        border: solid 4px $white;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
        transition: all 200ms ease-out;
    }

    .close-reveal-modal:hover,
    .close-reveal-modal:focus
     {
        box-shadow: 0 0 6px 1px rgba(225, 10, 9, 0.5);
    }
}
