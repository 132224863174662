/**
 * VIDEO
 */
.video {

    &__associated p {
        font-size: .85rem;
    }

    &__associated-head {
        margin-bottom: 1.1rem;
    }

    &__associated-body {
        list-style: none;
        margin-left: 0;
        height: 700px;
        overflow-x: hidden;
        overflow-y: auto;
    }

}

.video-category-block  {

    .video-category__title {
        margin-top: 1.25rem;
    }

}
