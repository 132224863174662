.footer-logo-block {
    margin-top: 2.75rem;
    margin-left: 15%;
    margin-right: 15%;

    & li {
        padding-right: 5%;
        padding-bottom: 2rem;
        text-align: center;
    }

    & li:last-child {
        padding-right: 5%;
    }

    @media (min-width: 24em) {
        & img {
            max-width: 200px;
        }
    }

    @media (min-width: 40em) {
        & img {
            max-height: 100%;
            max-width: 100%;
        }
        & li {
            margin-bottom: 1rem;
        }
    }

}
