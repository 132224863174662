$slider-button-size: 40px;

.slider {

    margin-left: -10px;
    margin-right: -10px;

    &.slick-slider {
        margin-bottom: 45px;
    }

    .slick-prev,
    .slick-next {
        top: 105%;
        height: $slider-button-size;
        width: $slider-button-size;
    }

    .slick-prev:before,
    .slick-next:before {
        color: $secondary-color;
        font-size: $slider-button-size;
    }

    .slick-prev {
        left: 8px;
    }

    .slick-next {
        right: 8px;
    }

    .slick-dots {
        left: 45px;
        right: 45px;
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }

    @media (max-width: 900px) {
        .slick-prev,
        .slick-next {
            top: 40%;
            height: $slider-button-size;
            width: $slider-button-size;
        }

        .slick-prev {
            left: 15px;
        }

        .slick-next {
            right: 15px;
        }

        .slick-prev:before,
        .slick-next:before {
            text-shadow: 1px 1px 10px $black;
        }

    }

}

.slider-item {
    position: relative;
    margin: 10px;

    @media (max-width: 900px) {
        img {
            width: 100%;
        }
    }
}
