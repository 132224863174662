.page-number {
    position: relative;
    text-align: center;
    line-height: 1.75;
    width: 1.75rem;
    border-radius: 1rem;
    border: 1px solid #ccc;
    margin: 20px auto 20px auto;
}

.page-number:before {
    content: "";
    border-bottom: 1px solid #ccc;
    position: absolute;
    width: 140px;
    top: .75rem;
    left: -140px;
}

.page-number:after {
    content: "";
    border-bottom: 1px solid #ccc;
    position: absolute;
    width: 140px;
    top: .75rem;
    left: 1.75rem;
}
