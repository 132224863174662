.ibook {

    h1 {
        color: $tertiary-color;
    }

    h1, h2, h3, h4, h5, h6 {
        a {
            color: $primary-color;
            font-size: 1rem;
        }
        a:hover, a:focus {
            color: $primary-color;
        }
    }

}
