hr.rule {
    &--horizontal {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    &--halfwidth {
        margin-right: 35%;
        margin-left: 35%;
    }
}
