/*
//http://zurb.com/building-blocks/top-bar-with-mobile-and-desktop-search-bar
.has-form {
  position: absolute;
  top: 0;
  left: 6rem; // adjust distance from left here
  min-width: 14rem; // adjust width here

  @media only screen and (max-width: 40em) {
    min-width: 10rem;
    top: 0.5rem;

    .button {
      height: 1.85rem;
    }
  }
}
*/
