.top-links {
    text-align: left;
    height: 20px;
    margin-bottom: 5px;
    padding: 1em 1em 1em .4em;
    height: 4em;
}

@media (min-width: 700px) {
    .top-links {
        position: absolute;
        right: 0;
        margin-bottom: 0;
        width: 300px;
        height: calc(#{$top-branding-height} - 45px);
    }
}

